import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {addCustomerCompanyAction} from '../../../../../store/mpWizard/actions'
import {updateData} from '../../../../../store'

import LinkingToLegalEntity from '../../LinkingToLegalEntity'

import {IWizardState} from '../../../../../store/mpWizard/types'
import {StorageType} from '../../../../../../setup'
import {TCompany} from '../../../../../store/mpWizard/types'

const OzonStep4 = () => {
  const {currentStep, data}: IWizardState = useSelector(
    (state: StorageType) => state.mpWizard,
    shallowEqual
  )

  const currentStepKey = `step${currentStep}`

  const selectedCustomerCompany: TCompany | null = data?.[currentStepKey]?.customerCompany?.value
  const action = data?.[currentStepKey]?.customerCompany?.action

  const dispatch = useDispatch()

  const onSelectCustomerCompany = (cc: TCompany | null) => {
    dispatch(
      updateData({
        key: currentStepKey,
        field: 'customerCompany',
        value: {value: cc, action: 'select'},
      })
    )
  }

  const onCancelSelection = () => {
    dispatch(updateData({key: currentStepKey, field: 'customerCompany', value: null}))
  }

  const onAddCustomerCompany = (cc: TCompany) => {
    dispatch(addCustomerCompanyAction({company: cc}))
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Для правильного функционирования сервиса нам необходимо привязать личный кабинет к
        юридическому лицу, которое им владеет. Вы можете выбрать его из имеющихся или создать новое.
      </p>

      <LinkingToLegalEntity
        selectedCC={selectedCustomerCompany}
        onCancelSelection={onCancelSelection}
        action={action}
        onSelectCustomerCompany={onSelectCustomerCompany}
        addCustomerCompanyHandler={onAddCustomerCompany}
      />
    </div>
  )
}

export default OzonStep4
