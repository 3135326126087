import {FC} from 'react'
import {Field, useField} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import CloseButton from '../../../../../../components/buttons/CloseButton'
import FormikFieldError from '../../../messages/formik/FormikFieldError'
import SignedField from './../../../../../../components/HOC/SignedField'
import FieldHeader from './FieldHeader'
import css from './TextField.module.css'

export interface IFormikTextField {
  fieldName: string
  label?: string
  description?: string
  placeholder?: string
  defaultEmptyValue: any
  type: string
  as?: 'input' | 'select' | 'textarea' | 'date'
  noArrows?: boolean
  visualValidation?: boolean
  isClearBtn?: boolean
  rightIcon?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  oninput?: string
  otherProps?: {
    [key: string]: any
  }

  maxSimbols?: number
  isEmptyLabelHide?: boolean // if true, then empty values label hide and placeholder has label's text
}

const TextFieldRow: FC<IFormikTextField> = (props) => {
  const intl = useIntl()
  const [field, meta, helpers] = useField(props.fieldName)

  const clearField = () => {
    helpers.setValue(props.defaultEmptyValue)
  }

  const validate = (value: string) => {
    let errorMessage
    if (props.maxSimbols && value.length > props.maxSimbols) {
      errorMessage = intl.formatMessage({id: 'VALIDATION.GOODS.MAXSYMBOLS'})
    }
    return errorMessage
  }

  return (
    <div className={`${props.className ? props.className : 'd-flex flex-column mb-7 fv-row'}`}>
      {props.label && (!props.isEmptyLabelHide || field.value?.length) ? (
        <FieldHeader label={props.label ?? ''} description={props.description} />
      ) : null}
      <SignedField
        Field={
          <>
            <Field
              validate={validate}
              type={props.type}
              rows='5'
              className={clsx('form-control px-3 form-control-solid poosition-relative ', {
                [css.noArrows]: props.noArrows,
                'border border-danger bg-danger bg-opacity-10':
                  meta.touched && meta.error && props.visualValidation,
                'border border-success bg-success bg-opacity-10':
                  meta.touched && !meta.error && props.visualValidation,
              })}
              placeholder={props.isEmptyLabelHide ? props.label ?? '' : props.placeholder ?? ''}
              name={props.fieldName}
              as={props.as ?? 'input'}
              {...props.otherProps}
            />
            {props.maxSimbols ? (
              <div
                className={`ms-3 mt-1 w-25 ${
                  field.value && props.maxSimbols - field.value.length < 0
                    ? 'text-danger'
                    : 'text-muted'
                }`}
              >
                {field.value?.length ?? 0}/{props.maxSimbols}{' '}
                {intl.formatMessage({id: 'COMPONENTS.FIELDS.TEXTAREA.MESSAGE.SYMBOLS'})}
              </div>
            ) : null}
          </>
        }
        Sign={
          <>
            {props.rightIcon && <div className='cursor-pointer'>{props.rightIcon}</div>}
            {props.isClearBtn ? <div></div> : <CloseButton onClick={clearField} />}
          </>
        }
        className='w-100'
      >
        {props.children}
      </SignedField>
      <div className='text-danger mt-2 w-100'>
        <FormikFieldError fieldName={props.fieldName} />
      </div>
    </div>
  )
}

export default TextFieldRow
