import {FC, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setSelectedToken} from '../../../store'
import SelectInput from '../../../components/common/select/SelectInput'
import {useGetTokensQuery} from '../../../store/tokens/api/tokensApiSlice'

import {IOption} from '../../../utils/types'
import {ITokenFilters} from '../../../store/tokens/types'
import {StorageType} from '../../../../setup'

interface ITokenTypeSelectProps {
  filters: ITokenFilters
  className?: string
  disabled?: boolean
}

const TokenSelect: FC<ITokenTypeSelectProps> = ({filters, className, disabled}) => {
  const selectedToken: IOption | null = useSelector(
    (state: StorageType) => state.mpWizard.selectedToken
  )

  const {data: tokens = [], isFetching, isLoading} = useGetTokensQuery(filters)

  const dispatch = useDispatch()

  const transformedTokens = useMemo(
    () =>
      tokens.map((t) => ({
        value: t.id.toString(),
        label: `${t.marketplace} - ${t.token_type?.name || ''}`,
      })),
    [tokens]
  )

  const loading = isFetching || isLoading

  const onSelectToken = (item: IOption) => {
    dispatch(setSelectedToken(item.label ? item : null))
  }

  return (
    <SelectInput
      className={className}
      isMulti={false}
      disabled={disabled}
      isClearable
      isLoading={loading}
      placeholder='Выберите токен'
      selectedItems={selectedToken}
      setSelected={onSelectToken}
      items={transformedTokens}
      notFoundMessage='Токены не найдены'
    />
  )
}

export default TokenSelect
