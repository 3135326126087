import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../utils/constants'
import {IPriceExtended} from '../prices/types'
import {IGoodTradeOfferStock} from '../../modules/goods/types'
let checkThreeState: 'checked' | 'unchecked' | 'halfcheck'
export type TCheckThreeState = typeof checkThreeState

export const emptyGoodGroupUrlParameters: IUrlParameters = {
  ...emptyUrlParameters,
  url: '/goods/groups/list/',
}

type Interval = {
  gt: number
  lt: number
}

type IGoodsGroupParamsExclude = {
  sku_id: {id: string; name: string}[] | []
  goods_id: {id: string; name: string}[] | []
  skus_customer_uid: string
  goods_customer_uid: string[]
}

type IGoodsGroupSendParamsExclude = {
  sku_id: string[] | []
  goods_id: string[] | []
  skus_customer_uid: string[] | ''
  goods_customer_uid: string[] | []
}

type PriceType = {
  id: string | undefined
  label: string
  gt: number
  lt: number
}

export interface IGoodsGroupParams {
  price: Interval
  stock: Interval
  sku_id: {id: number; name: string}[] | null
  excluded: IGoodsGroupParamsExclude
  goods_id: {id: number; name: string}[] | null
  goods_name: string | null
  category_id: {id: number; text: string}[] | null
  ozon_special: null
  price_types: PriceType[]
  skus_customer_uid: string
  goods_customer_uid: string[]
}

export interface IGoodsGroupSendParams {
  price: Interval
  stock: Interval
  sku_id: number[] | null
  excluded: IGoodsGroupSendParamsExclude
  goods_id: number[] | null
  goods_name: string[]
  category_id: number[] | null
  ozon_special: null
  skus_customer_uid: string[] | null | ''
  goods_customer_uid: string[] | []
}

export interface IFilteredGoodsGroupUrlParameters extends IUrlParameters {
  whId: {
    label: string
    value: number
  } | null
}

export interface IFilteredGoodsGroupRequest {
  body: IGoodsGroupSendParams
  urlParams: IFilteredGoodsGroupUrlParameters
}

export interface IGoodsGroup {
  pk: number
  name: string
  params: IGoodsGroupParams
  selected?: TCheckThreeState
}

export interface IGoodsGroupForSend {
  pk: number
  name: string
  params: IGoodsGroupSendParams
  selected?: TCheckThreeState
}
export const emptyGoodsGroup: IGoodsGroup = {
  pk: 0,
  name: '',
  params: {
    price: {gt: 0, lt: 0},
    stock: {gt: 0, lt: 0},
    sku_id: [],
    excluded: {sku_id: [], goods_id: [], skus_customer_uid: '', goods_customer_uid: []},
    price_types: [],
    goods_id: null,
    goods_name: '',
    category_id: [],
    ozon_special: null,
    skus_customer_uid: '',
    goods_customer_uid: [],
  },
  selected: 'unchecked',
}

export interface IGoodsGroups {
  count: number
  next: string | null
  previous: string | null
  results: IGoodsGroup[]
}

export interface IFilteredGood {
  id: number
  customer_uid: string
  name: string
  params: any
  barcodes: string[]
  good: string
  stock: IGoodTradeOfferStock[]
  prices: IPriceExtended[]
}

export interface IGoodsGroupsState {
  goodsGroups: IGoodsGroups
  isLoading: boolean
  // filters: IGoodsGroupSendParams
}

export interface IFilteredGoodsResponse {
  count: number
  result: IFilteredGood[]
}

export interface IGoodsGroupRequestParams {
  limit?: number
  offset?: number
}
export const initialGoodsGroups: IGoodsGroupsState = {
  isLoading: false,
  goodsGroups: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  // filters: {
  //   price: {
  //     gt: 0,
  //     lt: 0,
  //   },
  //   stock: {
  //     gt: 0,
  //     lt: 0,
  //   },
  //   sku_id: null,
  //   excluded: {
  //     sku_id: [],
  //     goods_id: [],
  //     skus_customer_uid: [],
  //     goods_customer_uid: [],
  //   },
  //   goods_id: null,
  //   goods_name: null,
  //   category_id: null,
  //   ozon_special: null,
  //   skus_customer_uid: [],
  //   goods_customer_uid: [],
  // },
}

export interface IGoodsGroupsStorage {
  isLoading: boolean
  items: IGoodsGroup[]
  selected: IGoodsGroup | null
  count: number
  next: string | null
  previous: string | null
  filters: IUrlParameters
  filteredGoods: IFilteredGood[]
}

export const emptyState: IGoodsGroupsStorage = {
  isLoading: false,
  items: [],
  selected: null,
  count: 0,
  next: null,
  previous: null,
  filters: emptyGoodGroupUrlParameters,
  filteredGoods: [],
}

export type TEnumGoodsGroupsActionTypes =
  | 'FetchGoodsGroups'
  | 'FetchGoodsGroupById'
  | 'UpdateGoodsGroupById'
  | 'DeleteGoodsGroupById'
  | 'FetchFilteredGoods'

export const EnumGoodsGroupsActionTypes = {
  FetchGoodsGroups: 'FetchGoodsGroups' as TEnumGoodsGroupsActionTypes,
  FetchGoodsGroupById: 'FetchGoodsGroupById' as TEnumGoodsGroupsActionTypes,
  UpdateGoodsGroupById: 'UpdateGoodsGroupById' as TEnumGoodsGroupsActionTypes,
  DeleteGoodsGroupById: 'DeleteGoodsGroupById' as TEnumGoodsGroupsActionTypes,
  FetchFilteredGoods: 'FetchFilteredGoods' as TEnumGoodsGroupsActionTypes,
}
