import {EnumMarketPlaceCode, TEnumMarketPlaceCode} from '../../../store/marketplaces/types'
import {TMarketplace, TStep} from '../../../store/mpWizard/types'
import {
  ozonLogo,
  wbLogo,
  ymLogo,
  avitoLogo,
  sberLogo,
} from '../../../../_metronic/assets/media/images/marketplaces'

import {WBStep1, WBStep2, WBStep3, WBStep4, WBStep5} from '../components/WBSteps'
import {YMStep1, YMStep2, YMStep3, YMStep4} from '../components/YMSteps'
import {OzonStep1, OzonStep2, OzonStep3, OzonStep4, OzonStep5} from '../components/OzonSteps'

export const marketplaces: TMarketplace[] = [
  {
    id: 1,
    code: EnumMarketPlaceCode.WB,
    img: wbLogo,
  },
  {
    id: 2,
    code: EnumMarketPlaceCode.OZON,
    img: ozonLogo,
  },
  {
    id: 5,
    code: EnumMarketPlaceCode.YM,
    img: ymLogo,
  },
  {
    id: 4,
    code: EnumMarketPlaceCode.SBER,
    img: sberLogo,
  },
  {
    id: 6,
    code: EnumMarketPlaceCode.AVITO,
    img: avitoLogo,
  },
]

export const steps: Record<TEnumMarketPlaceCode, TStep[]> = {
  wb: [
    {title: 'Шаг 2. Токен авторизации личного кабинета', component: WBStep1},
    // {
    //   title: 'Шаг 3. Авторизация в личный кабинет (необязательно)',
    //   component: WBStep2,
    //   canSkip: true,
    //   skipCount: 2,
    // },
    // {title: 'Шаг 4. Выберите кабинет', component: WBStep3},
    {title: 'Шаг 3. Привязка к юридическому лицу', component: WBStep4},
    {title: 'Шаг 4. Название кабинета', component: WBStep5},
  ],
  yandex: [
    {title: 'Шаг 2. Токен авторизации личного кабинета', component: YMStep1},
    {title: 'Шаг 3. Импорт кабинетов', component: YMStep2},
    {title: 'Шаг 4. Привязка к юридическому лицу', component: YMStep3},
    {title: 'Шаг 5. Название кабинета', component: YMStep4},
  ],
  ozon: [
    {title: 'Шаг 2. Токен авторизации личного кабинета', component: OzonStep1},
    // {title: 'Шаг 3. Токен рекламного кабинета', component: OzonStep2, canSkip: true, skipCount: 1},
    {title: 'Шаг 3. Куки', component: OzonStep3, canSkip: true, skipCount: 1},
    {title: 'Шаг 4. Привязка к юридическому лицу', component: OzonStep4},
    {title: 'Шаг 5. Название кабинета', component: OzonStep5},
  ],
  sbmm: [],
  avito: [],
  ali: [],
  cdek: [],
  '': [],
}
