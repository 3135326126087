import {Formik, Form, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useResetPasswordMutation} from '../../../store/auth/AuthCRUD'
import {intl} from '../../goods/item/validation'

import TextFieldRow from '../../goods/item/tabs/TabInformation/Fields/TextFieldRow'
import {Button} from 'react-bootstrap'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.INVALID_EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.EMAIL'})),
})

const ChangePassword = () => {
  const [resetPassword, {isLoading}] = useResetPasswordMutation()

  const onSubmit = (values: {email: string}, helpers: FormikHelpers<{email: string}>) => {
    helpers.resetForm()
    resetPassword(values)
  }

  return (
    <div>
      <Formik initialValues={{email: ''}} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => (
          <>
            <Form noValidate>
              <TextFieldRow
                label='Введите почту для восстановления пароля'
                defaultEmptyValue=''
                fieldName='email'
                type='email'
                placeholder='Email'
              />

              <Button disabled={isLoading} className='w-100 mb-6' variant='primary' type='submit'>
                Отправить пароль на почту
              </Button>

              <Link to='/auth/login' className='text-center d-block'>
                Вернуться на страницу входа
              </Link>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export default ChangePassword
